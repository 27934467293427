@media only screen and (max-width: 523px) {
    .formacao h2{
      /* color: red !important */
    }
  }
  

  .formacao {
    margin-top: 40px;
  }

.sobre  {
    background-color: rgba(229, 219, 204, 1) !important;
    color: hsla(35, 48%, 22%, 1) !important;
    padding: 15rem 2rem;
    margin-right: 0px !important;
}

.sobre .c{
margin-bottom: 0 !important;
line-height: 30px !important;
}


.sobre-box{
    margin-top: 50px !important;
}

.pos-graduacao {
    margin-top: 45px;
}