.form{
  background: #f7f6f5;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 2rem;
  margin: 0 auto !important;
  margin-top: 50px !important;
}
.btn {
  margin-top: 20px;
  margin-bottom: 20px;
}

.form-outline {
  margin-top: 20px;
}

.form-control {
  background: rgba(229, 219, 204, 1) !important;
  border: none !important;

height: 40px;
}

.texto-form {
  color: hsla(35, 48%, 22%, 1);
}

::placeholder {
  color: hsla(35, 48%, 22%, 1) !important;
  font-size: 13px;
}

.btn-primary:hover {
  background: hsla(34, 30%, 45%, 1) !important;
  border: none !important;
  color: hsla(34, 33%, 85%, 1) !important;
  font-weight: bold !important;
  height: 40px;
}

.btn-primary {
    background: hsla(35, 48%, 22%, 1) !important;
    border: none !important;
    color: hsla(34, 33%, 85%, 1) !important;
    font-weight: bold !important;
    height: 40px;
    width: 148px;
  }

  .texto-contato{
      color: hsla(35, 48%, 22%, 1) !important;
  }

  .texto-contato p{
    color: rgba(184, 161, 130, 1) !important; 
}
  .texto-contato h3{

    font-family: "Playfair Display SC" !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 14px !important;
    line-height: 16px !important;
    /* identical to box height */
    
    font-feature-settings: 'cpsp' on;
}

  
  .icon-contato{
    font-size: 25px;
    color: hsla(35, 48%, 22%, 1);
    margin-right: 20px;
    text-decoration: none;
    margin-bottom: 0;
  }

  .contato {
      background-color: rgba(229, 219, 204, 1) !important;
      color: hsla(35, 48%, 22%, 1) !important;
      padding: 15rem 2rem;
      margin-right: 0px !important;
  }

  .texto{
    margin-top: 20px;
  }



div.input {
    margin-bottom: 24px;
    position: relative;
}


.form-outline span.error {
    display: flex;
    padding: 0;
    background-color: none;
    font-size: 12px;
    color: rgb(100, 30, 30);
}

.form-outline span.error.active {
    border-bottom: 1px solid rgb(100, 30, 30);
    padding: 0.4em;
}