@media only screen and (min-width: 1200px) {
  .nav-item > .nav-link {
    padding: 5px 20px !important;
    display: block !important;
  }
}
@media only screen and (max-width: 992px) {
  .header-inner {
    background-color: rgba(23, 22, 22, 1) !important;
  }
  .nav-item > .nav-link {
    color: rgba(229, 219, 204, 1) !important;
    margin-top: 15px;
  }
}


.text-center{
  color: rgba(229, 219, 204, 1);
}

.btn-b:hover {
  font-weight: bold !important;
  background-color: rgba(229, 219, 204, 1) !important;
  color: #533D1D !important;
  width: 202px !important;
  height: 40px !important;
  border: 1px solid rgba(229, 219, 204, 0.75) !important;
  box-sizing: border-box;
  border-radius: 5px;
}

.btn-b {
  font-weight: bold !important;
  color: rgba(229, 219, 204, 1) !important;
  width: 202px !important;
  height: 40px !important;
  border: 1px solid rgba(229, 219, 204, 0.5) !important;
  box-sizing: border-box;
  border-radius: 5px;
}

.banner-con {
  margin-top: 200px;
}

.logo img {
  height: 80px !important;
  margin: -3px 0px 0px 0px;
}

.content-banner {
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #ffffff;
  background-image: url("background-header.jpg");
  min-height: 800px;
  height: 100%;
  position: relative;
  transform: translateZ(1px);
  padding-top: 125px;
}

.content-banner::before {
  position: relative;
  content: "";
  width: 100%;
  height: 100%;
  background-color: rgba(57, 49, 49, 0.7);
  top: 0;
  left: 0;
  z-index: 1;
}

.content-banner .first-title {
  font-family: "Playfair Display SC";
  font-style: normal;
  font-weight: bold;
  font-size: 42px;
  line-height: 56px;
  position: relative;
  z-index: 2;
  margin: 0px 0px 12px;
}

.content-banner .banner-des {
  font-family: "Montserrat";
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 1px;
  position: relative;
  z-index: 2;
  font-weight: 400;
  margin-top: 0;
}

.banner-btn {
  padding: 0 34px;
  z-index: 1;
  margin: 30px auto 25px;
  line-height: 56px;
  position: relative;
  border-radius: 6px;
  height: 56px;
  font-size: 16px;
  background-color: black;
  display: inline-block;
  color: white;
  transition: 0.3s;
  text-align: center;
}

.banner-btn:hover {
  text-decoration: none;
  color: white;
}

.header {
  position: relative;
  width: 100%;
}

.header-inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  background-color: transparent;
  opacity: 1;
}

.nav-item .nav-link {
  font-family: "Playfair Display";
  letter-spacing: 2px;
  font-weight: bold;
  display: block;
  line-height: 36px;
  text-transform: uppercase;
  font-size: 14px;
  color: rgba(229, 219, 204, 1);
  transition: 0.15s;
  border-bottom: 1px solid transparent;
}

.nav-item .nav-link:hover {
  border-bottom: 1px solid rgba(229, 219, 204, 1);
}

.active {
  border-bottom: 1px solid rgba(229, 219, 204, 1);
}

.header-btn {
  color: #ffffff;
  background-color: grey;
  border: none;
  font-weight: 500;
  outline: none;
  font-size: 15px;
  padding: 7px 22px;
}

.navbar-scroll {
  background-color: rgba(23, 22, 22, 1);
  padding: 0;
  position: fixed;
  top: 0;
  z-index: 99;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.4);
  transition-duration: 0.6s;
}

.navbar-scroll .nav-item .nav-link {
  color: rgba(229, 219, 204, 1);
}

.navbar {
  padding: 10px !important;
}
