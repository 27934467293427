.navbar {
}

.navbar-light .navbar-nav .nav-link {
  color: blanchedalmond !important;
}

.navbar-light .navbar-brand {
  color: blanchedalmond !important;
}
