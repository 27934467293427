.atuacao {
  color: rgba(229, 219, 204, 1) !important;
  background-color: rgba(23, 22, 22, 1) !important;
  padding: 10rem 0rem;
  margin-right: 0px !important;
}
.atuacao-text{
  line-height: 40px;
}
.atuacao-card {
  background-color: rgba(229, 219, 204, 1);
  color: rgba(84, 61, 29, 1);
  width: 370px;
  height: 270px;
  border-radius: 5px;
  margin-top: 50px !important;
}
.text-card {
margin-top: 30px;
line-height: 25px;
}

.title-card {
margin-top: 20px;
font-family: "Playfair Display SC" !important;
}


.cards {
  column-gap: 40px;
}

.img-icon img{
  width: 67px !important; 
  height: 57px !important;
}

.line-title {
border-bottom: 1px solid hsla(35, 48%, 22%, 1);
}

.topo {
  margin-top: 20px;
}