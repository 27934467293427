.Footer{
    background-color: rgba(23, 22, 22, 1) !important;
    color: rgba(229, 219, 204, 1);
}
.text-footer{
    line-height: 20px;
}
.Footer a{
    text-decoration: none;
    color: rgba(229, 219, 204, 1);
}
.Footer a:hover{
    text-decoration: none;
    color: rgba(229, 219, 204, 1);
}

.Footer .p-3{
border-top: 1px solid rgba(229, 219, 204, 0.200);
}
.footer-link{
    padding-bottom: 100px !important;
    padding-top: 100px !important;
}
.footer-link img{
    height: 100px;
    margin-bottom: 20px;
}
.icon {
    font-size: 25px;
    color: rgba(229, 219, 204, 1);
    margin-right: 20px;
    text-decoration: none;
}
