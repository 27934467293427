body {
  margin: 0;
  font-family: "Montserrat", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgba(229, 219, 204, 1) !important;
} 
#subirTopo {
  text-decoration: none;
  background: hsla(34, 3%, 9%, 1);
  border: 1px solid rgba(229, 219, 204, 1);
  bottom: 30px;
  right: 30px;
  border-radius: 2px;
}
body {
    margin: 0;
    font-family: "Montserrat", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
      "Droid Sans", "Helvetica Neue", sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: rgba(229, 219, 204, 1) !important;
  }
  #subirTopo {
    text-decoration: none;
    background: hsla(34, 3%, 9%, 1);
    border: 1px solid rgba(229, 219, 204, 1);
    bottom: 30px;
    right: 30px;
    border-radius: 5px;
    color: rgba(229, 219, 204, 1);
    text-align: center;
    cursor: pointer;
    padding: 15px;
    font-size: 15px;
    font-weight: bold;
    text-transform: uppercase;
    position: fixed;
    font-family: sans-serif;
    opacity: 0.8;
    z-index: 1;
  }

  #subirTopo:hover {
    opacity: 1;
  }

  body::-webkit-scrollbar {
    background-color: hsla(34, 3%, 9%, 1) !important;
    width: 1em;
  }

  body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  body::-webkit-scrollbar-thumb {
    background-color: rgba(229, 219, 204, 1) !important;
    border-radius: 5px;
  }

  p {
    font-size: 12px;
  }
  p.l {
    font-size: 12px;
  }
  p.c {
    font-size: 14px;
  }
  p.b {
    font-size: 14px;
    margin: 0px;
  }
  h1 {
    font-family: "Playfair Display SC" !important;
    font-size: 32px !important;
    font-weight: bold !important;
    margin-bottom: 40px !important;
  }
  h2 {
    font-family: "Playfair Display SC" !important;
    font-size: 22px !important;
    font-weight: bold !important;
  }
  h3 {
    font-family: "Playfair Display SC" !important;
    font-size: 14px !important;
    font-weight: bold !important;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }

  .row {
    margin: 0 !important;
  }

  .header-inner{    
  text-align: center;
  cursor: pointer;
  padding: 15px;
  font-size: 15px;
  font-weight: bold;
  text-transform: uppercase;
  position: fixed;
  font-family: sans-serif;
  opacity: 0.8;
  z-index: 1;
}

#subirTopo:hover {
  opacity: 1;
}

body::-webkit-scrollbar {
  background-color: hsla(34, 3%, 9%, 1) !important;
  width: 1em;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: rgba(229, 219, 204, 1) !important;
  border-radius: 5px;
}

p {
  font-size: 12px;
}
p.l {
  font-size: 12px;
}
p.c {
  font-size: 14px;
}
p.b {
  font-size: 14px;
  margin: 0px;
}
h1 {
  font-family: "Playfair Display SC" !important;
  font-size: 32px !important;
  font-weight: bold !important;
  margin-bottom: 40px !important;
}
h2 {
  font-family: "Playfair Display SC" !important;
  font-size: 22px !important;
  font-weight: bold !important;
}
h3 {
  font-family: "Playfair Display SC" !important;
  font-size: 14px !important;
  font-weight: bold !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.row {
  margin: 0 !important;
}
